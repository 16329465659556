import { noteIcon } from "base/SVG";
import React from "react";
import styles from "./tooltip.module.scss";
import classNames from "classnames";
export default function Tooltip({ text, type }) {
  return (
    <div className={classNames(styles["tooltip"], styles?.[type])}>
      <div className={styles["tooltip__icon"]}>{noteIcon}</div>
      <div className={styles["tooltip__text"]}>
        <div className={styles["tooltip__text-inner"]}>{text}</div>
      </div>
    </div>
  );
}
