import React, { useState } from "react";
import Button from "components/primary-button/button";
import { dotsIcon, plusIcon, solanaIcon } from "base/SVG";
import styles from "../../endpoints.module.scss";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/dropdown/dropdown";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../../../config';


/*const endpointsList = [
  {
    id: "1",
    name: "Main NextBlock Endpoint",
    icon: solanaIcon,
    status: "active",
    network: "Solana Mainnet",
    addons: "",
  },
];*/
export default function Endpoints({ endpointsList }) {
  const navigate = useNavigate();
  return (
    <div className={styles["main__inner"]}>
      <div className={styles["main__head"]}>
        <div className={styles["main__head-text"]}>
          <h4>Endpoints</h4>
          <p className="sm">
            Endpoints are used to interact with NextBlock’s services. Each
            endpoint is optimized to deliver fast, reliable responses.
          </p>
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Network</th>
                <th></th>
                {/*<th>Add-ons</th>
                <th></th>*/}
              </tr>
            </thead>
            <tbody>
              {
                endpointsList.length === 0 && (
                  <tr>
                    <td colSpan={4}>No endpoints found</td>
                  </tr>
                )
              }
              {endpointsList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className={styles["table__name"]}>
                        <div className={styles["table__name-icon"]}>
                          {/*item.icon*/}
                          {
                            item.network.includes("Solana") && (
                              solanaIcon
                            )
                          }
                        </div>
                        <div className={styles["table__name-text"]}>
                          {item.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles["table__status"]}>
                        {item.status.toLowerCase() === "active" && (
                          <>
                            <span className={styles["active"]}></span>
                            Active
                          </>
                        )}{" "}
                        {item.status.toLowerCase() === "paused" && (
                          <>
                            <span className={styles["paused"]}></span>
                            Paused
                          </>
                        )}
                      </div>
                    </td>
                    <td>{item.network}</td>
                    <td>
                      <Button
                        onClick={() => navigate("manage?id=" + item.user_endpoint_id)}
                        type={"primary"}
                        text={"Manage Endpoint"}
                        icon={plusIcon}
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </td>
                    {/*<td>{item.addons}</td>
                    <td>
                      <Dropdown btnInner={dotsIcon}>
                        <button type="button">Delete </button>
                      </Dropdown>
                    </td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
