import { eyeIcon } from "base/SVG";
import React, { useState } from "react";
import styles from "./input-pass.module.scss";
export default function InputPass({
  label,
  icon,
  placeholder,
  onChange,
  value,
}) {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className={styles["input__outer"]}>
      <label htmlFor="">{label}</label>
      <div className={styles["input"]}>
        <input
          type={isShown ? "text" : "password"}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        <span>{icon}</span>
        <button type="button" onClick={() => setIsShown(!isShown)}>
          {eyeIcon}
        </button>
      </div>
    </div>
  );
}
