import React, { useState } from "react";
import qr from "assets/images/qr.png";
import styles from "../add-funds.module.scss";
import { copyIcon, noteIcon } from "base/SVG";
import { motion } from "framer-motion";
import Button from "components/primary-button/button";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Step2({ setModal, setStep, loaded, pay_address, pay_amount, pay_currency, network, expiration_estimate_date, balance_history_id }) {

  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    
    <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    exit={{ opacity: 0, y: 20 }} className={styles["block"]}>
      <ToastContainer />
      <div className={styles["block__head"]}>
        <h4>Send Funds to Complete Deposit</h4>
        <p className="sm">
          Send the specified amount to the wallet address below.
        </p>
      </div>
      <div className={styles["block__qr"]}>
        <div className={styles["block__qr-price"]}>
          <h5>{pay_amount}</h5>
          <p>{pay_currency.toUpperCase().replace("USDCSOL", "USDC")}</p>
          <p>
            <small>{network.toUpperCase()}</small>
          </p>
        </div>
        <div className={styles["block__qr-image"]} style={{marginBottom: "10px"}}>
          <div style={{ height: "auto", margin: "auto", width: "100%" }}>
            <QRCode
              size={1024}
              style={{ height: "auto", width: "100%" }}
              value={pay_address}
              viewBox={`0 0 1024 1024`}
            />
          </div>
        </div>
        <div className={styles["address"]}>
          {pay_address}
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(pay_address);
              toast.success("Address copied to clipboard", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }}
          >
            {copyIcon}
          </button>
        </div>
        {/*<p className={styles["note"]}>
          {noteIcon}
          Only transfer Tether USD (ERC20) assets to this address. Other assets
          will be lost forever.
        </p>*/}
        <p className={styles["note"]}>
          {noteIcon}
          Only transfer {pay_currency.toUpperCase().replace("USDCSOL", "USDC")} ({network.toUpperCase()}) assets to this address. Other assets will be lost forever.
        </p>
      </div>
      <div className={styles["block__btns"]}>
        <Button
          type={"gray"}
          text={"Cancel"}
          onClick={() => {
            setModal(false);
          }}
        />
        <Button
          type={"primary"}
          text={"Continue"}
          onClick={() => setStep("3")}
        />
      </div>
    </motion.div>
  );
}
