import React from "react";
import ReactDom from "react-dom";
import { motion } from "framer-motion";
import styles from "./modal.module.scss";
import classNames from "classnames";

export default function Modal({ children, closeFunc, type }) {
  return ReactDom.createPortal(
    <motion.div
      className={classNames(styles["modal"], styles?.[type])}
      onClick={closeFunc}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles["modal__inner"]}>{children}</div>
    </motion.div>,
    document.getElementById("popups")
  );
}
