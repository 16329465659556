import React, { useMemo, useState } from "react";
import styles from "./table.module.scss";
//import { tableList } from "./modul";
import { arrowBtnDown } from "base/SVG";
import { Pagination } from "components/pagination/pagination";

import Modal from "../../../../components/modal/modal";
import { AnimatePresence } from "framer-motion";
import AddFunds from "components/add-funds/add-funds";


export default function Table({tableList}) {
  const [activeList, setActiveList] = useState(tableList);
  const [currentPage, setCurrentPage] = useState(1);
  
  
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  console.log("activelist", activeList, "curretn", currentTableData);

  const removeTimestamp = (date) => {
    date = new Date(date);
    return date.toDateString();
  };

  const [payment, setPayment] = useState(false);
  const [stepOverride, setStepOverride] = useState("4");
  const [amountOverride, setAmountOverride] = useState("");
  const [pay_currency_override, setPayCurrencyOverride] = useState("");
  const [networkOverride, setNetworkOverride] = useState("");
  const [transactionTypeOverride, setTransactionTypeOverride] = useState("Deposit");
  const [pay_amount_override, setPayAmountOverride] = useState("");
  const [paymentLinkOverride, setPaymentLinkOverride] = useState("");
  const [balance_history_id_override, setBalanceHistoryIdOverride] = useState("");

  const showPayment = (item) => {
    if (item.status.toLowerCase() === "confirmed") {
      setStepOverride("SuccessInfo");
    } else if (item.status.toLowerCase() === "declined") {
      setStepOverride("ErrorInfo");
    } else if (item.status.toLowerCase() === "processing") {
      setStepOverride("3");
    }
    setAmountOverride(item.amount);
    setPaymentLinkOverride("https://spherepay.co/pay/" + item.paymentLink_id_spherepay + "?email=" + item.email);
    setPayCurrencyOverride("USDC");
    setNetworkOverride("SOL");
    setTransactionTypeOverride(item.type);
    setBalanceHistoryIdOverride(item.balance_history_id);


    /*setPayCurrencyOverride(item.pay_currency);
    setNetworkOverride(item.network);
    setTransactionTypeOverride(item.type);*/

    setPayAmountOverride(item.amount);

    setPayment(true);
  }

  return (
    <div className={styles["table__outer"]}>
      <h4>Transaction History</h4>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction type</th>
                <th>Amount</th>
                <th>Payment method</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center">
                    No transaction history yet
                  </td>
                </tr>
              )}
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index} onClick={() => showPayment(item)}>
                    <td>{removeTimestamp(item.date)}</td>
                    <td>{item.type}</td>
                    <td>{item.amount}</td>
                    <td>{item.method}</td>
                    <td>
                      <div className={styles["table__status"]}>
                        {item.status.toLowerCase() === "confirmed" && (
                          <>
                            <span className={styles["confirmed"]}></span>
                            Confirmed
                          </>
                        )}{" "}
                        {item.status.toLowerCase() === "declined" && (
                          <>
                            <span className={styles["declined"]}></span>
                            Declined
                          </>
                        )}
                        {item.status.toLowerCase() === "processing" && (
                          <>
                            <span className={styles["processing"]}></span>
                            Processing
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <button type="button">{arrowBtnDown}</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <AnimatePresence>
        {payment && (
          <Modal setModal={setPayment}>
            <AddFunds balance_history_id_override={balance_history_id_override} paymentLinkOverride={paymentLinkOverride} transactionTypeOverride={transactionTypeOverride} setModal={setPayment} stepOverride={stepOverride} amountOverride={amountOverride} pay_currency_override={pay_currency_override} networkOverride={networkOverride} pay_amount_override={pay_amount_override} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
}
