import React, { useState } from "react";
import feature1 from "assets/images/landing/feature1.png";
import feature2 from "assets/images/landing/feature2.png";
import feature3 from "assets/images/landing/feature3.png";
import feature4 from "assets/images/landing/feature4.png";
import styles from "./feature.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
const featureList = [
  {
    id: "1",
    title: "Easy Integration",
    image: feature1,
    text: "Transition from your existing services seamlessly and effortlessly with our easy-to-use API, available via HTTP or gRPC.",
  },
  {
    id: "2",
    title: "Cost-per-transaction Model",
    image: feature2,
    text: "Simply include our NextBlock Tip IX with at least 0.001 SOL. Our API  will validate you and land your transaction with lightning speed. Higher fees mean higher priority and quicker landing.",
  },
  {
    id: "3",
    title: "Dedicated SWQoS",
    image: feature3,
    text: "Every user is allocated a dedicated amount of SWQoS that is not shared  with others. With our high capacity, we can service thousands of clients with our existing stake, which is the largest in the industry.",
  },
  {
    id: "4",
    title: "MEV-Protect",
    image: feature4,
    text: "Avoid getting MEV'd by enabling our anti-frontrun when submitting to our endpoint. All transactions will be guaranteed to be private and will be submitted through a priority fast lane.",
  },
];
export default function Feature() {
  const [isActive, setIsActive] = useState("1");
  return (
    <div className={styles["feature"]}>
      <div className="auto__container">
        <div className={styles["feature__head"]}>
          <h2>Powerful Features</h2>
          <p className="big">
            Built to enhance speed, scalability, and flexibility for every
            transaction.
          </p>
        </div>
        <div className={styles["feature__inner"]}>
          {featureList.map((item, index) => {
            return (
              <FeatureItem
                isActive={isActive}
                setIsActive={setIsActive}
                {...item}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
const FeatureItem = (props) => {
  return (
    <div className={styles["featureItem"]}>
      <div
        className={classNames(styles["featureItem__content"], {
          [styles.active]: props.isActive === props.id,
        })}
        onClick={() => props.setIsActive(props.id)}
      >
        <h4>{props.title}</h4>
        <p>{props.text}</p>
      </div>
      {props.isActive === props.id && (
        <AnimatePresence>
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            initial={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.4 }}
            className={styles["featureItem__image"]}
          >
            <div className={styles["featureItem__image-inner"]}>
              <img src={props.image} alt="feature" />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};
