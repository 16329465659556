import React from "react";
import styles from "./quick-access.module.scss";
import { arrowRight, cardIcon, keyIcon, trackIcon } from "base/SVG";
import { Link } from "react-router-dom";
const accessList = [
  {
    id: "1",
    icon: keyIcon,
    title: "Create an API key",
    link: "/app/endpoints-api",
    text: "Start integrating with our API",
  },
  {
    id: "2",
    icon: cardIcon,
    title: "Set up billing",
    link: "/app/billing",
    text: "Unlock access to the API",
  },
  {
    id: "3",
    icon: trackIcon,
    title: "Track your usage",
    link: "/app/usage-analytics",
    text: "Deep dive into your usage",
  },
];
export default function QuickAccess() {
  return (
    <div className={styles["access"]}>
      <p className={styles["access__title"]}>Quick Access</p>
      <div className={styles["access__row"]}>
        {accessList.map((item, index) => {
          return <Item {...item} key={index} />;
        })}
      </div>
    </div>
  );
}
const Item = (props) => {
  return (
    <Link to={props.link} className={styles["accessItem"]}>
      <div className={styles["accessItem__icon"]}>{props.icon}</div>
      <div className={styles["accessItem__content"]}>
        <h5>{props.title}</h5>
        <div className={styles["accessItem__content-text"]}>
          <p className="sm">{props.text}</p>
          {arrowRight}
        </div>
      </div>
    </Link>
  );
};
