import React, { useState } from "react";
import styles from "./create-api.module.scss";
import { copyIcon, noteIcon, successIcon } from "base/SVG";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export default function CreateSuccess({ setModal, createdApiKey }) {
  return (
    <div className={styles["success"]}>
      <div className={styles["success__icon"]}>{successIcon}</div>
      <h4>API Key Created Successfully</h4>
      <p className="sm">
        Here is your new API key. Make sure to store it securely.
      </p>
      <div className={styles["input"]}>
        <input type="text" value={createdApiKey?.api_key} />
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(createdApiKey?.api_key);
            toast.success("API key copied to clipboard", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }}
        >
          {copyIcon}
        </button>
      </div>
      {/*<p className={styles["note"]}>
        {noteIcon}
        If you lose this key, you will need to generate a new one.
      </p>*/}
      <div className={styles["success__btn"]} style={{display: 'flex', justifyContent: 'center'}}>
        <Button onClick={() => setModal(null)} text="Complete" type="primary" />
      </div>
    </div>
  );
}
