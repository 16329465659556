import InputEmail from "components/input-email/input-email";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../auth.module.scss";
import InputPass from "components/input-pass/input-pass";
import { lockIcon, noteIcon } from "base/SVG";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';


export default function ResetPassword() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const [form, setForm] = useState({
    password: "",
  });
  const [passwordWeak, setPasswordWeak] = useState(false);

  useEffect(() => {
    const password = form.password;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (passwordRegex.test(password)) {
      setPasswordWeak(false);
    } else {
      console.log("Password is weak");
      setPasswordWeak(true);
    }
  }, [form.password]);

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const setNewPassword = async () => {
    const password = form.password;

    if (passwordWeak) {
      toast.error("Password is weak", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      return;
    }

    try {
      const response = await axios.post(SERVER_URL + '/api/auth/reset-password', { password },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      // Check if status code is 200
      if (response.status === 200) {
        setSuccess(true);
      } else {
        toast.error("Failed to update password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
    }
    catch (error) {
      toast.error("Failed to update password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }

  };

  return (
    <>
      <ToastContainer />
      {
        success && (
          <div className={styles["auth__inner-head"]}>
            <h3>Password Updated Successfully</h3>
          </div>
        )
      }
      {
        !success && (
          <>
            <div className={styles["auth__inner-head"]}>
              <h3>Change Password</h3>
            </div>
            <InputPass
              label="New Password"
              icon={lockIcon}
              placeholder={"Enter your new password"}
              onChange={(e) => updateForm({ password: e.target.value })}
            />
            {
              passwordWeak && (
                <p className={styles["note"]}>
                  {noteIcon}
                  Password Strength: At least eight characters, one uppercase, one
                  lowercase, a number and a special character
                </p>
              )
            }
            <div className={styles["space"]}></div>
            <div className={styles["space"]}></div>
            <div className={styles["auth__inner-btn"]}>
              <Button
                onClick={() => setNewPassword()}
                text="Update Password"
                type={"primary"}
                disabled={isSubmitting}
              />
            </div>
          </>
        )
      }
      <div className={styles["auth__inner-back"]}>
        <Link to="/auth/sign-in">Back to Login</Link>
      </div>
    </>
  );
}
