import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import InputPass from "components/input-pass/input-pass";
import { lockIcon, noteIcon } from "base/SVG";
import Button from "components/primary-button/button";
export default function ChangePassword({ setModal, updatePassword }) {

  const [passwordWeak, setPasswordWeak] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    const password = newPassword;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (passwordRegex.test(password)) {
      setPasswordWeak(false);
    } else {
      console.log("Password is weak");
      setPasswordWeak(true);
    }
  }, [newPassword]);

  return (
    <div className={styles["content"]}>
      <h4>Change Password</h4>
      <InputPass
        label="Old Password"
        icon={lockIcon}
        placeholder={"Enter your old password"}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <InputPass
        label="New Password"
        icon={lockIcon}
        placeholder={"Enter your new password"}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      {
        passwordWeak && (
          <p className={styles["note"]}>
            {noteIcon}
            Password Strength: At least eight characters, one uppercase, one
            lowercase, a number and a special character
          </p>
        )
      }
      <div className={styles["content__btns"]}>
        <Button type="gray" text="Cancel" onClick={() => setModal(null)} />
        <Button
          type="primary"
          text="Change Password"
          onClick={() => updatePassword(oldPassword, newPassword)}
        />
      </div>
    </div>
  );
}
