import React, { useState } from "react";
import styles from "../add-funds.module.scss";
import { motion } from "framer-motion";
import Select from "components/select/select";
import Button from "components/primary-button/button";


export default function Step1({ setModal, setStep, list, selectedCurrency, setSelectedCurrency, amount, setAmount }) {

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, y: 20 }}
      className={styles["block"]}
    >
      <h4>Add Funds to Your Wallet</h4>
      <div className={styles["input__outer"]}>
        <label htmlFor="">Select Crypto</label>
        <Select list={list} selected={selectedCurrency} onChange={setSelectedCurrency} />
      </div>
      <div className={styles["input__outer"]}>
        <label htmlFor="">Enter Amount</label>
        <div style={{ position: "relative" }} className={styles["input"]}>
          <input
            type="text"
            placeholder="0.00"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <span style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", color: "#999" }}>{selectedCurrency.value}</span>
        </div>
      </div>
      {
        amount && amount < 15 &&
        <p style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>Minimum amount is $15.00</p>
      }
      <div className={styles["space"]}></div>
      <div className={styles["block__btns"]}>
        <Button
          type={"primary"}
          text={"Continue"}
          onClick={() => setStep("2")}
          disabled={!selectedCurrency || !amount || amount < 15}
        />
        <Button
          type={"gray"}
          text={"Cancel"}
          onClick={() => {
            setModal(false);
          }}
        />
      </div>
    </motion.div>
  );
}
