import React from "react";

import cn from "classnames";
import { DOTS, usePagination } from "base/usePagination";
import styles from "./pagination.module.scss";
import { chevronLeftP, chevronRightP } from "base/SVG";
export const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={styles.pagination}>
      <li
        className={cn(styles.pagination__item, styles.arrow, {
          [styles.disabled]: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        {chevronLeftP}
      </li>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li
              key={index}
              className={cn(styles.pagination__item, styles.dots)}
            >
              ...
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li
            key={index}
            className={cn(styles.pagination__item, {
              [styles.selected]: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={cn(styles.pagination__item, styles.arrow, {
          [styles.disabled]: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        {chevronRightP}
      </li>
    </ul>
  );
};
