import React from "react";
import styles from "./sub-plan.module.scss";
import Button from "components/primary-button/button";
import { useNavigate } from "react-router-dom";
export default function SubPlan({billingDetails}) {
  const navigate = useNavigate();

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }

    date = new Date(date);
    return date.toLocaleDateString('en-US');
  };

  const addMonths = (date, months) => {

    if (!date) {
      return "";
    }

    date = new Date(date);

    date.setMonth(date.getMonth() + months);
    return date.toLocaleDateString('en-US');
  };

  return (
    <div className={styles["plan"]}>
      <div className={styles["plan__title"]}>
        <p>My Subscription Plan</p>
      </div>
      <div className={styles["plan__inner"]}>
        <div className={styles["plan__row"]}>
          <div className={styles["planItem"]}>
            <h6>{billingDetails.subscription_plan} Plan</h6>
            <div className={styles["planItem__price"]}>
              <h3>${billingDetails.subscription_price}</h3>
              <p>/month</p>
            </div>
          </div>
          <div className={styles["planItem"]}>
            <p className="sm">Due on</p>
            <h4>{billingDetails.last_subscription_payment ? removeTimestamp(addMonths(billingDetails.last_subscription_payment, 1)) : "-"}</h4>
          </div>
          <div className={styles["planItem"]}>
            <p className="sm">TPS Allowance</p>
            <h4>
              {billingDetails.subscription_plan_id === 1 || !billingDetails.plan_active ? '1 TX per 10s' : billingDetails.tps_allowance}
            </h4>
          </div>
        </div>
        <Button
          type="gray"
          text="Manage Plan"
          onClick={() => navigate("/app/manage-plan")}
        />
      </div>
    </div>
  );
}
