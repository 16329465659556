import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styles from "./dropdown.module.scss";
import classNames from "classnames";
export default function Dropdown({ children, btnInner }) {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  return (
    <div className={styles["dropdown"]} ref={wrapper}>
      <button
        className={classNames(styles["dropdown__btn"], {
          [styles.active]: active,
        })}
        onClick={toggleActive}
        type="button"
      >
        {btnInner}
      </button>
      <AnimatePresence>
        {active === true && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
            className={classNames(styles["dropdown__content"], {
              [styles.active]: active,
            })}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
