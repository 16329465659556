import React, { useState } from "react";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
} from "base/constants";
import {
  minLength,
  stripeCardExpirValidation,
  stripeCardNumberValidation,
  textWithSpacesOnly,
} from "base/validation";
import MaskedInput from "react-text-mask";
import Button from "components/primary-button/button";
import styles from "./change-method.module.scss";
import Input from "components/input/input";
import { cardIcon } from "base/SVG";
import classNames from "classnames";
import CountrySelect from "components/contry-select/contry-select";
import { CountryList } from "base/country-modul";
export default function ChangeMethod({ setModal }) {
  const [form, setForm] = useState({
    fullName: "",
    cardNumber: "",
    expDate: "",
    cvv: "",
  });
  const [errorCard, setErrorCard] = useState(false);
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
        return card;
    }
    return "";
  }
  const [cardType, setCardType] = useState();

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case "card":
        let cardInfo = findDebitCardType(value);
        setCardType(findDebitCardType(value));
        updateForm({ cardImage: CARDICON[cardInfo], cardType: cardInfo });
        errorText = stripeCardNumberValidation(value);
        setErrorCard({ ...errorCard, cardNumberError: errorText });
        break;
      case "cardHodler":
        errorText = value === "" ? "Required" : textWithSpacesOnly(value);
        setErrorCard({ ...errorCard, fullNameError: errorText });
        break;
      case "expiry":
        errorText =
          value === "" ? "Required" : stripeCardExpirValidation(value);
        setErrorCard({ ...errorCard, expDateError: errorText });
        break;
      case "securityCode":
        errorText = value === "" ? "Required" : minLength(3)(value);
        setErrorCard({ ...errorCard, cvvError: errorText });
        break;
      default:
        break;
    }
  };

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };
  console.log(errorCard);
  return (
    <div className={styles["form"]}>
      <h4>Change Payment Method</h4>
      <div
        // className={
        //   "input__outer" +
        //   (errorCard &&
        //   errorCard.cardNumberError &&
        //   errorCard.cardNumberError.length > 1
        //     ? "error"
        //     : "")
        // }
        className={classNames(styles["input__outer"], styles["card"], {
          [styles.error]:
            errorCard &&
            errorCard.cardNumberError &&
            errorCard.cardNumberError.length > 1,
        })}
      >
        <label>Card number</label>
        <div className={styles["input"]}>
          <MaskedInput
            mask={
              ["37", "34"].includes(
                form.cardNumber &&
                  form.cardNumber.split("").splice(0, 2).join("")
              )
                ? AMERICANEXPRESS
                : OTHERCARDS
            }
            guide={false}
            placeholderChar={"\u2000"}
            placeholder="Card Number"
            name="card"
            required
            value={form.cardNumber}
            onChange={onChangeInput("cardNumber")}
            onBlur={handleBlur}
          />
          {errorCard &&
            errorCard.cardNumberError &&
            errorCard.cardNumberError.length > 1 && (
              <span className={styles["tool"]}>
                {errorCard.cardNumberError}
              </span>
            )}
          <div className={styles["icon"]}>
            {(!errorCard || !errorCard.cardError) &&
            CARDARR.includes(cardType) ? (
              <img src={CARDICON[cardType]} alt="card" />
            ) : (
              cardIcon
            )}
          </div>
        </div>
      </div>
      <div className={styles["input__row"]}>
        <div
          className={classNames(styles["input__outer"], styles["w-50"], {
            [styles.error]:
              errorCard &&
              errorCard.expDateError &&
              errorCard.expDateError.length > 1,
          })}
        >
          <label>Expiration Date</label>
          <div className={styles["input"]}>
            <MaskedInput
              mask={EXPIRYDATE}
              guide={false}
              name="expiry"
              required
              placeholderChar={"\u2000"}
              placeholder="MM/YY"
              value={form.expDate}
              onChange={onChangeInput("expDate")}
              onBlur={handleBlur}
            />
            {errorCard &&
              errorCard.expDateError &&
              errorCard.expDateError.length > 1 && (
                <span className={styles["tool"]}>{errorCard.expDateError}</span>
              )}
          </div>
        </div>

        <div
          className={classNames(styles["input__outer"], styles["w-50"], {
            [styles.error]:
              errorCard && errorCard.cvvError && errorCard.cvvError.length > 1,
          })}
        >
          <label>CVC </label>
          <div className={styles["input"]}>
            <MaskedInput
              mask={CVC}
              guide={false}
              name="securityCode"
              required
              placeholderChar={"\u2000"}
              placeholder="CVV"
              value={form.cvv}
              onChange={onChangeInput("cvv")}
              onBlur={handleBlur}
            />
            {errorCard &&
              errorCard.cvvError &&
              errorCard.cvvError.length > 1 && (
                <span className={styles["tool"]}>{errorCard.cvvError}</span>
              )}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles["input__outer"], {
          [styles.error]:
            errorCard &&
            errorCard.fullNameError &&
            errorCard.fullNameError.length > 1,
        })}
      >
        <label>Full Name</label>
        <div className={styles["input"]}>
          <input
            type="text"
            placeholder="CardHolder's Name"
            name="cardHodler"
            onChange={onChangeInput("fullName")}
            onBlur={handleBlur}
            value={form.fullName}
          />
          {errorCard &&
            errorCard.fullNameError &&
            errorCard.fullNameError.length > 1 && (
              <span className={styles["tool"]}>{errorCard.fullNameError}</span>
            )}
        </div>
      </div>
      <div className={styles["input__outer"]}>
        <label htmlFor="">Country</label>
        <CountrySelect list={CountryList} />
      </div>
      <Input placeholder={"Street address"} label={"Address"} />
      <div className={styles["space"]}></div>
      <div className={styles["form__btns"]}>
        <Button onClick={() => setModal(false)} type={"gray"} text={"Cancel"} />
        <Button
          onClick={() => setModal(false)}
          type={"primary"}
          text={"Update"}
        />
      </div>
    </div>
  );
}
