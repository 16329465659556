import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { logoutIcon, settingsIcon, walletIcon, noteIcon } from "base/SVG";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.png";
import classNames from "classnames";
import Account from "components/account/account";

export default function Header({ setPayment, setMenu, menu, setHide, user, logout }) {
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState(null);
  useEffect(() => {
    if (pathname.includes("/usage-analytics")) {
      setPageTitle("Usage Analytics");
    } else if (pathname.includes("/endpoints-api")) {
      setPageTitle("Endpoint & API Key");
    } else if (pathname.includes("/billing")) {
      setPageTitle("Billing");
    } else if (pathname.includes("/support")) {
      setPageTitle("Documentation");
    } else if (pathname.includes("/settings")) {
      setPageTitle("Settings");
    } else if (pathname.includes("/manage-plan")) {
      setPageTitle("Manage Plan");
      setHide(true);
    } else {
      setPageTitle("Dashboard");
    }
  }, [pathname]);
  const navigate = useNavigate();

  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["header__mob"]}>
          <Link to="/app/dashboard" className={styles["header__mob-logo"]}>
            <img src={logo} alt="" />
          </Link>
          <div
            className={classNames(styles["burger"], {
              [styles.active]: menu,
            })}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
        <div className={styles["header__inner"]}>
          <div className={styles["header__inner-title"]}>
            <h4 className="big">{pageTitle}</h4>
            <div className={styles["header__inner-type"]}>{user.subscription_plan} Plan</div>
          </div>
          <div className={styles["header__inner-action"]}>
            <div id="balanceBtn" className={styles["balance"]} onClick={() => setPayment(true)}>
              <div className={styles["balance__icon"]}>{walletIcon}</div>
              <div className={styles["balance__title"]}>
                Balance: <span>${user.balance.toFixed(2)} USDC</span>
              </div>
            </div>
            <Account
              btnInner={
                <>
                  <div className={styles["avatar"]}>
                    <div className={styles["avatar__title"]}>{user.first_name[0]}</div>
                  </div>
                </>
              }
            >
              <button type="button" onClick={() => navigate("/app/settings")}>
                {settingsIcon} <span>Settings</span>
              </button>
              <button type="button" onClick={() => logout()}>
                {logoutIcon} <span>Logout</span>
              </button>
            </Account>
          </div>
        </div>
      </div>
    </>
  );
}
