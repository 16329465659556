import React, { useEffect, useState } from "react";
import SignIn from "./pages/SignIn";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SignUp from "./pages/SignUp";
import ForgetPassword from "./pages/ForgetPassword";
import Mail from "./pages/Mail";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";

import logo from "assets/images/logo.png";
import shape from "assets/images/shapes/shape3.png";
import styles from "./auth.module.scss";
import classNames from "classnames";
export default function Auth() {
  const { pathname } = useLocation();
  const [center, setCenter] = useState(false);
  useEffect(() => {
    if (pathname.includes("/forget-password")) {
      setCenter(true);
    } else if (pathname.includes("/mail")) {
      setCenter(true);
    } else {
      setCenter(false);
    }
  }, [pathname]);
  return (
    <div
      className={classNames(styles["auth"], {
        [styles.center]: center,
      })}
    >
      <div className={styles["auth__shape"]}>
        <img src={shape} alt="shape" />
      </div>
      <div className={styles["auth__inner"]}>
        <div className={styles["auth__inner-logo"]}>
          <img src={logo} alt="logo" />
        </div>
        <Routes>
          <Route path="" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/mail" element={<Mail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </div>
    </div>
  );
}
