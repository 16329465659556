import Button from "components/primary-button/button";
import React from "react";
import styles from "./join.module.scss";
import shape1 from "assets/images/shapes/shape1.png";
import shape2 from "assets/images/shapes/shape2.png";
import { Link } from "react-router-dom";
export default function Join() {
  return (
    <div className={styles["join"]}>
      <div className="auto__container">
        <div className={styles["join__inner"]}>
          <div className={styles["join__shape1"]}>
            <img src={shape1} alt="shape" />
          </div>
          <div className={styles["join__shape2"]}>
            <img src={shape2} alt="shape" />
          </div>
          <div className={styles["join__inner-content"]}>
            <h2>Ready to Supercharge Your Solana Transactions?</h2>
            <p className="big">
              Be among the first to experience lightning-fast Solana
              transactions. No commitment, just pure speed.
            </p>
            <div className={styles["join__inner-content-btns"]}>
              <Button text="Create a Free Account" type={"primary"} onClick={() => window.location.href = "/auth"} />
              <Button text="Contact Sales" type={"gray"} onClick={() => window.location.href = "https://t.me/nextblocksol"} />
            </div>
            <p className="sm">
              By signing up, you agree to our{" "}
              <Link to="">Terms & Conditions</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
