import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppInner from "./App/AppInner";
import Landing from "./Landing/Landing";
import Auth from "./Auth/Auth";
import "assets/css/main.scss";
export default function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Landing />} />
        <Route path="/app/*" element={<AppInner />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </>
  );
}
