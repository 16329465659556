import React, { useState, useEffect } from "react";
import tether from "assets/images/icons/tether.png";
import usdc from "assets/images/icons/usdc.png";
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import Step4 from "./steps/step-4";

import SuccessInfo from "./steps/SuccessInfo";
import ErrorInfo from "./steps/ErrorInfo";

import SuccessInfoCharge from "./steps/SuccessInfoCharge";
import ErrorInfoCharge from "./steps/ErrorInfoCharge";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';


export default function AddFunds({ balance_history_id_override = "", paymentLinkOverride = "", transactionTypeOverride = "Deposit", stepOverride = "1", amountOverride = "", pay_currency_override = "", networkOverride = "", pay_amount_override = "", setModal }) {
  const [step, setStep] = useState(stepOverride);
  const [loaded, setLoaded] = useState(false);

  const [transactionType, setTransactionType] = useState(transactionTypeOverride); // Deposit, Subscription Payment, Subscription Downgrade, Subscription Upgrade

  const list = [
    {
      id: "1",
      value: "USDC",
      icon: usdc,
    },
  ];
  
  const [selectedCurrency, setSelectedCurrency] = useState(list[0]);
  const [amount, setAmount] = useState(amountOverride);

  useEffect(() => {
    // Only allow numbers and one decimal point in the amount input
    if (amount && transactionType === "Deposit") {
      if (!/^\d*\.?\d*$/.test(amount)) {
        setAmount(amount.slice(0, -1));
      }
    }
  }, [amount]);

  const [pay_address, setPayAddress] = useState("-");
  const [pay_amount, setPayAmount] = useState(pay_amount_override);
  const [pay_currency, setPayCurrency] = useState(pay_currency_override);
  const [network, setNetwork] = useState(networkOverride);
  const [expiration_estimate_date, setExpirationEstimateDate] = useState();
  const [balance_history_id, setBalanceHistoryId] = useState(balance_history_id_override);
  
  const [paymentLink, setPaymentLink] = useState(paymentLinkOverride);

  const [paymentStatus, setPaymentStatus] = useState("Processing");

  const token = document.cookie.split('; ').find(row => row.startsWith('token='));

  useEffect(() => {

    if (step === "2" && !loaded && pay_address == "-") {

      setLoaded(false);

      axios.post(SERVER_URL + '/api/billing/create-crypto-deposit', {
        amount: amount,
        currency: selectedCurrency.value
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setPayAmount(amount);
          setPayCurrency("USDC");
          setNetwork("SOL");

          /*setPayAddress(response.data.pay_address);
          setPayCurrency(response.data.pay_currency);
          setNetwork(response.data.network);
          setExpirationEstimateDate(response.data.expiration_estimate_date);
          */

          setBalanceHistoryId(response.data.balance_history_id);

          let paymentLinkID = response.data.paymentLinkID;

          setPaymentLink("https://spherepay.co/pay/" + paymentLinkID + "?email=" + response.data.email);

          // Open the payment link in a new tab
          window.open("https://spherepay.co/pay/" + paymentLinkID + "?email=" + response.data.email, "_blank");

          setStep("3");
          
          setLoaded(true);

        }).catch((error) => {
          console.log(error.response);
          setLoaded(true);

          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setStep("1");
          setLoaded(false);
        });

    } else if (step === "3" && paymentStatus === "Processing") {
      // Make a request to /api/billing/transaction-history/<balance_history_id> every 10 seconds to check the payment status
      const interval = setInterval(() => {
        axios.get(SERVER_URL + '/api/billing/transaction-history/' + balance_history_id, {
          headers: {
            'Authorization': `Bearer ${token.split('=')[1]}`,
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (response.data.status === "Confirmed") {
              setPaymentStatus("Confirmed");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }, 10000);

      return () => clearInterval(interval);
    }

  }, [step]);

  useEffect(() => {
    if(paymentStatus === "Confirmed") {
      setStep("4");
    }
  }, [paymentStatus]);

  return (
    <>
      {step === "1" && <Step1 setStep={setStep} setModal={setModal} list={list} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} amount={amount} setAmount={setAmount} />}
      {step === "2" && <Step2 setStep={setStep} setModal={setModal} loaded={loaded} pay_address={pay_address} pay_amount={pay_amount} pay_currency={pay_currency} network={network} expiration_estimate_date={expiration_estimate_date} balance_history_id={balance_history_id} />}
      {step === "3" && <Step3 paymentLink={paymentLink} setStep={setStep} setModal={setModal} loaded={loaded} pay_amount={pay_amount} pay_currency={pay_currency} network={network} />}
      {step === "4" && <Step4 setModal={setModal} amount={amount} />}
      
      {transactionType == "Deposit" && step === "SuccessInfo" && <SuccessInfo setModal={setModal} amount={amount} />}
      {transactionType == "Deposit" && step === "ErrorInfo" && <ErrorInfo setModal={setModal} amount={amount} />}

      {transactionType !== "Deposit" && step === "SuccessInfo" && <SuccessInfoCharge setModal={setModal} amount={amount} />}
      {transactionType !== "Deposit" && step === "ErrorInfo" && <ErrorInfoCharge setModal={setModal} amount={amount} />}
    </>
  );
}
