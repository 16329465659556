import React from "react";
import styles from "./modals.module.scss";
import Button from "components/primary-button/button";
export default function DeleteAccount({ setModal, deleteUser }) {
  return (
    <div className={styles["content"]}>
      <div className={styles["content__delete"]}>
        <h4>Are you sure you want to Delete your Account?</h4>
        <p className="sm">
          All your data, including usage history, API keys, and personal
          information, will be permanently erased and cannot be recovered.
        </p>
      </div>

      <div className={styles["content__btns"]}>
        <Button type="gray" text="Cancel" onClick={() => setModal(null)} />
        <Button
          type="primary"
          text="Yes, Delete"
          onClick={() => deleteUser()}
        />
      </div>
    </div>
  );
}
