import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./components/sidebar/sidebar";
import styles from "./layout.module.scss";
import Header from "./components/header/header";
import Dashboard from "./pages/dashboard/dashboard";
import Settings from "./pages/settings/settings";
import Support from "./pages/support/support";
import Billing from "./pages/billing/billing";
import EndpointsApi from "./pages/endpoints-api/endpoints-api";
import { AnimatePresence } from "framer-motion";
import Modal from "./components/modal/modal";
import ChangeMethod from "components/change-method/change-method";
import AddFunds from "components/add-funds/add-funds";
import UsageAnalytics from "./pages/usage-analytics/usage-analytics";
import Price from "./pages/manage-plan/price";
import classNames from "classnames";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../config';


export default function AppInner() {
  const { pathname } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [payment, setPayment] = useState(false);
  const [hideSide, setHide] = useState(false);
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (payment || paymentMethod || menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [payment, paymentMethod, menu]);

  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setUser(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  const logout = () => {
    // Delete token cookie
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Redirect to login page
    window.location.href = '../../auth/sign-in';
  };

  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    <>
      <Sidebar
        hideSide={hideSide}
        menu={menu}
        setMenu={setMenu}
        setPayment={setPayment}
        user={user}
        logout={logout}
      />
      <div className={styles["layout"]}>
        <div className={styles["container"]}>
          <div className={styles["layout__inner"]}>
            <Header
              setHide={setHide}
              setPayment={setPayment}
              setMenu={setMenu}
              menu={menu}
              user={user}
              logout={logout}
            />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/billing"
                element={<Billing setPaymentMethod={setPaymentMethod} />}
              />{" "}
              <Route
                path="/usage-analytics"
                element={<UsageAnalytics setPaymentMethod={setPaymentMethod} />}
              />
              <Route path="/endpoints-api/*" element={<EndpointsApi />} />
              <Route path="/support" element={<Support />} />
              <Route path="/manage-plan" element={<Price />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="*"
                element={<Navigate to="/app/dashboard" replace={true} />}
              />
            </Routes>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {paymentMethod && (
          <Modal setModal={setPaymentMethod}>
            <ChangeMethod setModal={setPaymentMethod} />
          </Modal>
        )}{" "}
        {payment && (
          <Modal setModal={setPayment}>
            <AddFunds setModal={setPayment} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
