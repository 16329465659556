import React, { useState, useEffect } from "react";
import styles from "./manage.module.scss";
import { chevronLeftP } from "base/SVG";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Modal from "App/components/modal/modal";
import RpcAccess from "./components/rpc-access";
import RpcUrl from "./components/rpc-url";
import CurlRequest from "./components/curl-request";

import AllowedItems from "./components/allowed-items-modal";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../../../config';

export default function Manage() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const [modal, setModal] = useState(null);

  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };

  const [loaded, setLoaded] = useState(false);

  const [rpcData, setRPCData] = useState({});

  useEffect(() => {
    getRPCData();
  }, []);

  const getRPCData = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/endpoints/user/' + id, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setRPCData(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  const addAllowedDomain = (value) => {
    // Make a POST to /api/endpoints/user/allowed_domains

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/endpoints/user/allowed_domains', {
        domain: value,
        user_endpoint_id: id
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedDomains = response.data;

          setRPCData({
            ...rpcData,
            allowed_domains: newAllowedDomains
          });
          
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  const removeAllowedDomain = (id) => {
    // Make a DELETE to /api/endpoints/user/allowed_domains/:id

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.delete(SERVER_URL + '/api/endpoints/user/allowed_domains/' + id, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success("Successfully removed domain", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedDomains = response.data;

          setRPCData({
            ...rpcData,
            allowed_domains: newAllowedDomains
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }

  }

  const addAllowedIP = (value) => {
    // Make a POST to /api/endpoints/user/allowed_ips

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/endpoints/user/allowed_ips', {
        ip: value,
        user_endpoint_id: id
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedIPs = response.data;

          setRPCData({
            ...rpcData,
            allowed_ips: newAllowedIPs
          });
          
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  const removeAllowedIP = (id) => {
    // Make a DELETE to /api/endpoints/user/allowed_ips/:id

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.delete(SERVER_URL + '/api/endpoints/user/allowed_ips/' + id, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success("Successfully removed IP", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedIPs = response.data;

          setRPCData({
            ...rpcData,
            allowed_ips: newAllowedIPs
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }

  }

  const addAllowedCIDR = (value) => {
    // Make a POST to /api/endpoints/user/allowed_cidrs

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/endpoints/user/allowed_cidrs', {
        cidr: value,
        user_endpoint_id: id
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedCIDRs = response.data;

          setRPCData({
            ...rpcData,
            allowed_cidrs: newAllowedCIDRs
          });
          
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  const removeAllowedCIDR = (id) => {
    // Make a DELETE to /api/endpoints/user/allowed_cidrs/:id

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.delete(SERVER_URL + '/api/endpoints/user/allowed_cidrs/' + id, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          toast.success("Successfully removed CIDR", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          
          let newAllowedCIDRs = response.data;

          setRPCData({
            ...rpcData,
            allowed_cidrs: newAllowedCIDRs
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }

  }

  return (
    <>
      <ToastContainer />
      <div className={styles["manage"]}>
        <Link to="/app/endpoints-api" className={styles["manage__back"]}>
          <div className={styles["manage__back-icon"]}>{chevronLeftP}</div>
          <span>Back</span>
        </Link>
        <div className={styles["manage__inner"]}>
          <RpcUrl rpcData={rpcData} />
          <RpcAccess rpcData={rpcData} setModal={setModal} />
          <CurlRequest rpcURL={rpcData.url_1} />
        </div>
      </div>
      <AnimatePresence>
        {modal === "allowed-domains" && (
          <Modal closeFunc={closeFunc}>
            <AllowedItems items={rpcData.allowed_domains} addItemFunction={addAllowedDomain} removeItemFunction={removeAllowedDomain} setModal={setModal} />
          </Modal>
        )}
        {modal === "allowed-ips" && (
          <Modal closeFunc={closeFunc}>
            <AllowedItems items={rpcData.allowed_ips} addItemFunction={addAllowedIP} removeItemFunction={removeAllowedIP} setModal={setModal} />
          </Modal>
        )}
        {modal === "allowed-cidrs" && (
          <Modal closeFunc={closeFunc}>
            <AllowedItems items={rpcData.allowed_cidrs} addItemFunction={addAllowedCIDR} removeItemFunction={removeAllowedCIDR} setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
