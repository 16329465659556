import React, { useEffect, useState } from "react";
import styles from "./endpoints.module.scss";
import { AnimatePresence } from "framer-motion";
import Modal from "App/components/modal/modal";
import CreateApi from "./components/create-api/create-api";
import CreateSuccess from "./components/create-api/create-success";
import Endpoints from "./components/endpoints/endpoints";
import Api from "./components/api/api";
import { Navigate, Route, Routes } from "react-router-dom";
import Manage from "./components/manage/manage";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../config';


export default function EndpointsApi() {
  const [modal, setModal] = useState(null);

  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(null);
  };

  const [createdApiKey, setCreatedApiKey] = useState('');

  const [loaded, setLoaded] = useState(false);

  const [apiList, setApiList] = useState([]);
  const [endpointsList, setEndpointsList] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    getApiKeys();
  }, []);

  useEffect(() => {
    getUserEndpoints();
  }, [apiList]);

  useEffect(() => {
    getUserData();
  }, [endpointsList]);

  const getUserData = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const getApiKeys = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/endpoint/api-keys', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setApiList(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const getUserEndpoints = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/endpoints/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setEndpointsList(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const deleteApiKey = (id) => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    }

    axios.delete(SERVER_URL + '/api/endpoint/api-keys/' + id, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Remove this api key from the list
        setApiList(apiList.filter(item => item.id !== id));
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      );
  };
  
  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    <>
      <ToastContainer />
      <div className={styles["main"]}>
        <Routes>
          <Route
            path=""
            element={
              <div className={styles["container"]}>
                <Endpoints endpointsList={endpointsList} />
                <Api setModal={setModal} apiList={apiList} deleteApiKey={deleteApiKey} user={user} />
              </div>
            }
          />
          <Route path="/manage" element={<Manage />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </div>
      <AnimatePresence>
        {modal === "create-api" && (
          <Modal closeFunc={closeFunc}>
            <CreateApi setModal={setModal} setCreatedApiKey={setCreatedApiKey} apiList={apiList} setApiList={setApiList} />
          </Modal>
        )}{" "}
        {modal === "create-success" && (
          <Modal type={"wide"} closeFunc={closeFunc}>
            <CreateSuccess setModal={setModal} createdApiKey={createdApiKey} />
          </Modal>
        )}{" "}
      </AnimatePresence>
    </>
  );
}
