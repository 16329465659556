import InputEmail from "components/input-email/input-email";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../auth.module.scss";
import { mailIcon, noteIcon } from "base/SVG";
import InputPass from "components/input-pass/input-pass";
import Button from "components/primary-button/button";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../config';


export default function ForgetPassword() {
  const [form, setForm] = useState({
    email: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);

  const requestResetPassword = async () => {
    //Make a post to /api/auth/request-password-reset with the email

    const email = form.email;

    try {
      const response = await axios.post(SERVER_URL + '/api/auth/request-password-reset', { email });

      // Check if status code is 200
      if (response.status === 200) {
        window.location.href = '../../auth/mail';
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
    }
    catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }

  };

  return (
    <>
      <div className={styles["auth__inner-head"]}>
        <h3>Forgot Password?</h3>
        <p>Enter your email address to reset your password</p>
      </div>
      <InputEmail
        label={"Email Address"}
        placeholder={"Enter your email"}
        onChange={(e) => {
          updateForm({ email: e.target.value });
        }}
        icon={mailIcon}
        value={form.email}
      />
      <div className={styles["space"]}></div>
      <div className={styles["space"]}></div>
      <div className={styles["auth__inner-btn"]}>
        <Button
          onClick={() => requestResetPassword()}
          text="Reset Password"
          type={"primary"}
          disabled={isSubmitting}
        />
      </div>
      <div className={styles["auth__inner-back"]}>
        <Link to="/auth/sign-in">Back to Login</Link>
      </div>
    </>
  );
}
