import React from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Why from "./components/why/why";
import Feature from "./components/feature/feature";
import Price from "./components/price/price";
import Join from "./components/join/join";
import Intro from "./components/intro/intro";

export default function Landing() {
  return (
    <>
      <Header />
      <main className="main">
        <div style={{ overflow: "hidden" }}>
          <Intro />
          <div className="anchor" id="feature"></div>
          <Why />
        </div>
        <Feature />

        <div className="anchor" id="pricing"></div>
        <Price />
        <div className="anchor" id="contact"></div>

        <Join />
      </main>
      <Footer />
    </>
  );
}
