import React, { useState } from "react";
import styles from "./modals.module.scss";
import { dotsIcon, plusIcon, solanaIcon } from "base/SVG";
import Button from "components/primary-button/button";
import Input from "components/input/input";
import Dropdown from "components/dropdown/dropdown";
import { closeIcon } from "base/SVG";
import { Link } from "react-router-dom";

export default function AllowedItems({ setModal, items, addItemFunction, removeItemFunction }) {

  const [value, setValue] = useState("");

  return (
    <div className={styles["content"]}>
      <h5 style={{display: "inline"}}>Allowed Items</h5>
      <div onClick={() => setModal(null)} className={styles["manage__back-icon"]}>{closeIcon}</div>
      <br />
      <br />
      <br />
      <Input
        label="Value"
        placeholder={"Value"}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className={styles["content__btns"]}>
        <Button
          type="primary"
          text="Add Item"
          onClick={() => addItemFunction(value)}
        />
      </div>
      <br />
      <div className={styles["space"]}></div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                items.length === 0 &&
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>No Items found</td>
                </tr>
              }
              {items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.value}</td>
                    <td>
                      <Dropdown btnInner={dotsIcon}>
                        <button type="button" onClick={() => removeItemFunction(item.id)}>Delete</button>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
