import React from "react";
import styles from "./why.module.scss";
import { globalWhy, priceWhy, swqosWhy, tpsWhy } from "base/SVG";
const whyList = [
  {
    id: "1",
    icon: globalWhy,
    title: "Global Infrastructure",
    text: "Redundant load balancers across US-West, US-East, and EU-West regions.",
  },
  {
    id: "2",
    icon: swqosWhy,
    title: "Largest SWQoS Pool",
    text: "Industry-leading Stake Weighted Quality of Service for unparalleled performance.",
  },
  {
    id: "3",
    icon: tpsWhy,
    title: "High TPS Capacity",
    text: "Custom TPS based on your budget, ensuring scalability for any project.",
  },
  {
    id: "4",
    icon: priceWhy,
    title: "Competitive Pricing",
    text: "High-performance services at prices that won't break the bank.",
  },
];
export default function Why() {
  return (
    <div className={styles["why"]}>
      <div className="auto__container">
        <div className={styles["why__head"]}>
          <h2>Why Choose NextBlock?</h2>
          <p className="big">
            Packed with Features for a Seamless Transaction Experience
          </p>
        </div>
        <div className={styles["why__inner"]}>
          {whyList.map((item, index) => {
            return <WhyItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const WhyItem = (props) => {
  return (
    <div className={styles["whyItem"]}>
      <div className={styles["whyItem__icon"]}>{props.icon}</div>
      <div className={styles["whyItem__content"]}>
        <h4>{props.title}</h4>
        <p>{props.text}</p>
      </div>
    </div>
  );
};
