import React from "react";
import styles from "./support.module.scss";
import { arrowRight, gitBookIcon } from "base/SVG";
import Button from "components/primary-button/button";
export default function Support() {
  return (
    <div className={styles["support"]}>
      <div className={styles["container"]}>
        <div className={styles["support__inner"]}>
          <div className={styles["support__head"]}>
            <h4>Need Help?</h4>
            <p className="sm">Find All the Resources You Need</p>
          </div>
          <div className={styles["support__body"]}>
            <div className={styles["support__body-inner"]}>
              <div className={styles["support__body-icon"]}>{gitBookIcon}</div>
              <div className={styles["support__body-content"]}>
                <h4>View our GitBook documentation</h4>
                <p className="sm">
                  Access FAQs, and detailed documentation on all features,
                  endpoints, and integrations.
                </p>
              </div>
            </div>
            <Button
              text="Go to Documentation"
              icon={arrowRight}
              type={"primary"}
              onClick={() => window.open("https://docs.nextblock.io/", "_blank")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
