import React from "react";
import styles from "../manage.module.scss";
import Tooltip from "components/tooltip/tooltip";
import { pencilIcon } from "base/SVG";
export default function RpcAccess({ rpcData, setModal }) {
  return (
    <div className={styles["block"]}>
      <div className={styles["manage__title"]}>
        <h4>RPC Access Control Rules</h4>
      </div>
      <div className={styles["block__inner"]}>
        <div className={styles["blockItem"]}>
          <div className={styles["blockItem__title"]}>
            <h5>Allowed Domains</h5>
            <Tooltip type={"left"} text="Allow requests from specific domains" />
            <button onClick={() => setModal("allowed-domains")} type="button">{pencilIcon}</button>
          </div>
          <div className={styles["blockItem__row"]}>
            {
              rpcData.allowed_domains.map((domain, index) => (
                <span key={domain.id}>{domain.value}</span>
              ))
            }
            {
              rpcData.allowed_domains.length === 0 && <span className={styles["blockItem__empty"]}>No values set</span>
            }
          </div>
        </div>{" "}
        <div className={styles["blockItem"]}>
          <div className={styles["blockItem__title"]}>
            <h5>Allowed IPs</h5>
            <Tooltip type={"left"} text="Allow requests from specific IPs" />
            <button type="button" onClick={() => setModal("allowed-ips")}>{pencilIcon}</button>
          </div>
          <div className={styles["blockItem__row"]}>
            {
              rpcData.allowed_ips.map((ip, index) => (
                <span key={ip.id}>{ip.value}</span>
              ))
            }
            {
              rpcData.allowed_ips.length === 0 && <span className={styles["blockItem__empty"]}>No values set</span>
            }
          </div>
        </div>{" "}
        <div className={styles["blockItem"]}>
          <div className={styles["blockItem__title"]}>
            <h5>Allowed CIDRs</h5>
            <Tooltip type={"left"} text="Allow requests from specific CIDRs" />
            <button type="button" onClick={() => setModal("allowed-cidrs")}>{pencilIcon}</button>
          </div>
          <div className={styles["blockItem__row"]}>
            {
              rpcData.allowed_cidrs.map((cidr, index) => (
                <span key={cidr.id}>{cidr.value}</span>
              ))
            }
            {
              rpcData.allowed_cidrs.length === 0 && <span className={styles["blockItem__empty"]}>No values set</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
