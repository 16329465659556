import React from "react";
import styles from "./modals.module.scss";
import Button from "components/primary-button/button";
export default function CancelSubscription({ setModal, cancelFunction }) {
  return (
    <div className={styles["content"]}>
      <div className={styles["content__delete"]}>
        <h4>Are you sure you want to Cancel your Subscription?</h4>
        <p className="sm">
          You will lose access to all premium features and will be downgraded to
          the Free plan at the end of the billing cycle.
        </p>
      </div>

      <div className={styles["content__btns"]}>
        <Button type="gray" text="Cancel" onClick={() => setModal(null)} />
        <Button
          type="primary"
          text="Yes, Cancel"
          onClick={() => cancelFunction()}
        />
      </div>
    </div>
  );
}
