import React from "react";
import styles from "./input.module.scss";
export default function Input({ label, placeholder, onChange, value }) {
  return (
    <div className={styles["input__outer"]}>
      <label htmlFor="">{label}</label>
      <div className={styles["input"]}>
        <input
          type="email"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
}
