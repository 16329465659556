import { copyIcon, dotsIcon, eyeIcon, plusIcon, noteIcon } from "base/SVG";

import React, { useEffect, useState } from "react";
import styles from "../../endpoints.module.scss";
import Button from "components/primary-button/button";
import Dropdown from "components/dropdown/dropdown";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Api({ setModal, apiList, deleteApiKey, maxApikeys = 1, user }) {
  const [keyShow, setKeyShow] = useState(null);

  const removeTimestamp = (date) => {

    if (!date) {
      return "";
    }

    date = new Date(date);
    return date.toDateString();
  };

  return (
    <>
      <div className={styles["main__head"]}>
        <div className={styles["main__head-text"]}>
          <h4>API Keys</h4>
          <p className="sm">
            API keys are used to securely authenticate requests and access
            NextBlock’s transaction endpoints. Keep your API key private to
            ensure account security.
          </p>
          {
            apiList.length >= maxApikeys &&
            <>
              <br />
              <p className={styles["note"]}>
                {noteIcon}
                You may only create a maximum of {maxApikeys} API key{maxApikeys>1 && "s"} per account at a time. To create a new API key, you must first delete a existing one.
              </p>
            </>
          }
        </div>
        <Button
          onClick={() => setModal("create-api")}
          type={"primary"}
          text={"Create API Key"}
          icon={plusIcon}
          disabled={apiList.length >= maxApikeys}
        />
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Key ID</th>
                <th>Created</th>
                <th>Last updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                apiList.length === 0 &&
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>No API keys found</td>
                </tr>
              }
              {apiList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      <div className={styles["table__key"]}>
                        <div className={styles["table__key-row"]}>
                          {keyShow === item.id ? (
                            <div className={styles["table__key-inner"]} style={{ maxWidth: "80%", overflowX: "scroll", height: "30px", scrollbarWidth: "thin" }}>
                              {item.api_key}
                            </div>
                          ) : (
                            <div className={styles["table__key-inner"]}>
                              ****************************
                            </div>
                          )}
                          <button
                            type="button"
                            onClick={() => {
                              if (keyShow !== item.id) {
                                setKeyShow(item.id);
                              } else {
                                setKeyShow(null);
                              }
                            }}
                          >
                            {eyeIcon}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(item.api_key);
                              toast.success("API key copied to clipboard", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                              });
                            }}
                          >
                            {copyIcon}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>{removeTimestamp(item.create_ts)}</td>
                    <td>{removeTimestamp(item.update_ts)}</td>
                    <td>
                      <Dropdown btnInner={dotsIcon}>
                        <button type="button" onClick={() => deleteApiKey(item.id)}>Delete</button>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
