import Button from "components/primary-button/button";
import React, { useEffect, useState } from "react";
import styles from "./price.module.scss";
import { chevronLeftP, tickIcon } from "base/SVG";
import { Link } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import PaymentMethod from "../../../components/payment-method/payment-method";
import { AnimatePresence } from "framer-motion";
import Modal from "../../../App/components/modal/modal";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../config';

export default function Price() {

  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [priceList, setPriceList] = useState([]);

  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false);
  const [selectedPlanLink, setSelectedPlanLink] = useState("");
  const [selectedPlanPrice, setSelectedPlanPrice] = useState("");
  const [selectedPlanID, setSelectedPlanID] = useState("");

  const token = document.cookie.split('; ').find(row => row.startsWith('token='));

  useEffect(() => {
    console.log(selectedPlanLink);

    let selectedPlan = priceList.find((item) => selectedPlanLink.includes(item.link));
    if(selectedPlan) {

      // Remove the $ sign from the price and convert it to a float
      let selectedPlanPrice = parseFloat(selectedPlan.price.replace("$", "").replace(",", ""));

      setSelectedPlanPrice(selectedPlanPrice);

      setSelectedPlanID(selectedPlan.id);
    }

    if(selectedPlanLink != "") {
      setShowPaymentMethodForm(true);
    } else {
      setShowPaymentMethodForm(false);
    }
  }, [selectedPlanLink]);

  const subscribeWBalance = () => {
    // Make a post to /api/billing/subscribe-w-balance
    axios.post(SERVER_URL + '/api/billing/subscribe-w-balance', {
      plan_id: selectedPlanID
    }, {
      headers: {
        'Authorization': `Bearer ${token.split('=')[1]}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowPaymentMethodForm(false);
        setSelectedPlanLink("");
        setSelectedPlanPrice("");
        setSelectedPlanID("");

        window.location.href = '../../../app/billing';
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowPaymentMethodForm(false);
        setSelectedPlanLink("");
        setSelectedPlanPrice("");
        setSelectedPlanID("");
      });
  };

  useEffect(() => {
    setPriceList([{
      id: "2",
      type: "Entry",
      price: "$399",
      active: user.subscription_plan === "Entry" ? true : false,
      link: "https://buy.stripe.com/test_cN28AreCmez85MIbII",
      list: [
        {
          id: "1",
          text: "5 TPS",
        },
        {
          id: "2",
          text: "Dedicated SWQoS",
        },
        {
          id: "3",
          text: "Anti-MEV",
        },
        {
          id: "4",
          text: "0.001 SOL Tip",
        },
      ],
    },
    {
      id: "3",
      type: "Intermediate",
      price: "$999",
      active: user.subscription_plan === "Intermediate" ? true : false,
      link: "https://buy.stripe.com/test_3csaIz51M8aKejeeUV",
      list: [
        {
          id: "1",
          text: "20 TPS",
        },
        {
          id: "2",
          text: "Dedicated SWQoS",
        },
        {
          id: "3",
          text: "Anti-MEV",
        },
        {
          id: "4",
          text: "0.001 SOL Tip",
        },
      ],
    },
    {
      id: "4",
      type: "Advanced",
      price: "$1,999",
      active: user.subscription_plan === "Advanced" ? true : false,
      link: "https://buy.stripe.com/test_3csaIz3XI3UuejebIK",
      list: [
        {
          id: "1",
          text: "50 TPS",
        },
        {
          id: "2",
          text: "Dedicated SWQoS",
        },
        {
          id: "3",
          text: "Anti-MEV",
        },
        {
          id: "4",
          text: "0.001 SOL Tip",
        },
      ],
    },
    {
      id: "5",
      type: "Enterprise",
      price: "Custom",
      active: user.subscription_plan === "Enterprise" ? true : false,
      link: "https://t.me/nextblocksol",
      list: [
        {
          id: "1",
          text: "100+ TPS",
        },
        {
          id: "2",
          text: "Dedicated SWQoS",
        },
        {
          id: "3",
          text: "Anti-MEV",
        },
        {
          id: "4",
          text: "0.001 SOL Tip",
        },
      ],
    },
  ]
    );

    setLoaded(true);
  }, [user]);

  useEffect(() => {
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }, []);

  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    <div className={styles["price"]}>
      <ToastContainer />
      <Link to="/app/billing" className={styles["price__back"]}>
        <div className={styles["price__back-icon"]}>{chevronLeftP}</div>
        <span>Back</span>
      </Link>
      <div className={styles["price__head"]}>
        <h2>Manage your Plan</h2>
        <p>
          Upgrade, downgrade, or cancel your plan at any time. Changes will take
          effect at the start of your next billing cycle.
        </p>
      </div>
      <div className={styles["price__inner"]}>
        {priceList.map((item, index) => {
          return <PriceItem {...item} key={index} user={user} setSelectedPlanLink={setSelectedPlanLink} />;
        })}
      </div>
      <AnimatePresence>
        {showPaymentMethodForm && (
          <Modal setModal={setShowPaymentMethodForm} >
            <PaymentMethod user={user} setSelectedPlanLink={setSelectedPlanLink} cardLink={selectedPlanLink} planPrice={selectedPlanPrice} subscribeWBalance={subscribeWBalance} />
          </Modal>
        )}{" "}
      </AnimatePresence>
    </div>
  );
}
const PriceItem = ({ user, type, price, link, list, active, setSelectedPlanLink }) => {
  return (
    <div className={styles["priceItem"]}>
      <h6>{type}</h6>
      <div className={styles["priceItem__price"]}>
        <h3>{price}</h3>
        {price !== "Custom" && <p>/month</p>}
      </div>
      <div className={styles["priceItem__btn"]}>
        {active ? (
          <Button text="Current Plan" type={"gray"} />
        ) : price === "Custom" ? (
          <Button text="Contact Sales" type={"primary"} onClick={() => window.open(link, "_blank")} />
        )/* : (
          <Button text="Accelerate" type={"primary"} onClick={() => window.open(link + "?prefilled_email=" + user.email, "_blank")} />
        )*/ : (
          <Button text="Accelerate" type={"primary"} onClick={() => setSelectedPlanLink(link + "?prefilled_email=" + user.email)} />
        )}
      </div>

      <ul>
        {list.map((item, index) => {
          return (
            <li {...index} key={index}>
              {tickIcon}
              {item.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
