import React, { useEffect, useState } from "react";
import styles from "./usage-analytics.module.scss";
import Table from "./components/table/table";
import Summary from "./components/summary/summary";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../config';

export default function UsageAnalytics() {
  const [loaded, setLoaded] = useState(false);

  const periodList = [
    {
      id: "1",
      value: "Last 7 days",
    },
    {
      id: "2",
      value: "Last 30 days",
    },
    {
      id: "3",
      value: "Last 3 months",
    },
  ];

  const [selectedPeriodList, setSelectedPeriodList] = useState(periodList[0]);

  const [usageData, setUsageData] = useState(undefined);
  const [errorLogs, setErrorLogs] = useState(undefined);

  useEffect(() => {

    if (usageData == undefined) {
      let startDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
      let endDate = new Date().toISOString().split('T')[0];

      getUsageData(startDate, endDate, () => {
        if (errorLogs == undefined) {
          console.log("Getting error logs");
          getErrorLogs("", "");
        }
      });
    }

  }, []);

  const getUsageData = (startDate, endDate, callback = undefined) => {
    setLoaded(false);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/analytics/usage?startDate=' + startDate + '&endDate=' + endDate, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setUsageData(response.data);
          
          if (callback) {
            callback();
          } else {
            setLoaded(true);
          }
          
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const getErrorLogs = (startDate, endDate) => {
    setLoaded(false);
    
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/analytics/error-logs?startDate=' + startDate + '&endDate=' + endDate, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {

          if (response.data.length === 0) {
            console.log("No data");
            setErrorLogs([]);
          }

          setErrorLogs(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const [form, setForm] = useState({
    startDate: "", //new Date()
    endDate: "",
  });

  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    <div className={styles["usage"]}>
      <div className={styles["container"]}>
        <div className={styles["usage__inner"]}>
          <Summary usageData={usageData} getUsageData={getUsageData} periodList={periodList} selectedPeriodList={selectedPeriodList} setSelectedPeriodList={setSelectedPeriodList} />
          <Table data={errorLogs} getNewData={getErrorLogs} form={form} setForm={setForm} />
        </div>
      </div>
    </div>
  );
}
