import React, { useState } from "react";
import styles from "../manage.module.scss";
import Tooltip from "components/tooltip/tooltip";
import { copyIcon } from "base/SVG";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function RpcUrl( {rpcData} ) {
  
  return (
    <div className={styles["rpc"]}>
      <div className={styles["manage__title"]}>
        <h4>RPC URLs</h4>
        <Tooltip type={"left"} text="Some text example here should be" />
      </div>
      <div className={styles["input__outer"]}>
        <label htmlFor="">RPC URL</label>
        <div className={styles["input"]}>
          <input type="text" value={rpcData.url_1} />
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(rpcData.url_1);
              toast.success("Endpoint copied to clipboard", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }}
          >
            {copyIcon}
          </button>
        </div>
        {
          rpcData.url_2 && (
            <div className={styles["input"]}>
              <input type="text" value={rpcData.url_2} />
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(rpcData.url_2);
                  toast.success("Endpoint copied to clipboard", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                }}
              >
                {copyIcon}
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
}
