import React from "react";
import styles from "./primary-button.module.scss";
import classNames from "classnames";
export default function Button({ text, icon, onClick, type, disabled, iconStyle, style = {} }) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles["button"], styles?.[type])}
      style={style}
    >
      {text} {icon}
    </button>
  );
}
