import React, { useMemo, useEffect, useState } from "react";
import styles from "./table.module.scss";
import { chevronBottom, copyIcon } from "base/SVG";
import { tableList } from "./modul";
import { CodeBlock } from "react-code-blocks";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import DateInput from "components/date-input/date-input";
import { Pagination } from "components/pagination/pagination";
export default function Table({ data, getNewData, form, setForm }) {
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    // Give every item in the data a unique id
    data.forEach((item, index) => {
      item.id = index;
    });
  }, [data]);

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));

    /*if (data.startDate && data.endDate) {

      // Format the dates to YYYY-MM-DD
      data.startDate = new Date(data.startDate).toISOString().split('T')[0];
      data.endDate = new Date(data.endDate).toISOString().split('T')[0];

      getNewData(data.startDate, data.endDate);
    }*/
  };



  const [activeList, setActiveList] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    if (activeList.length == 0 || activeList == undefined) {
      return [];
    }

    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <div className={styles["table__outer"]}>
      <div className={styles["table__action"]}>
        <h4>API Error Logs</h4>
        <div className={styles["table__action-date"]}>
          <DateInput
            startDate={form?.startDate}
            endDate={form?.endDate}
            updateForm={updateForm}
            getNewData={getNewData}
          />
        </div>
      </div>
      <div className={styles["table"]}>
        <div className={styles["table__inner"]}>
          <div className={styles["table__head"]}>
            <div className={styles["table__row"]}>
              <div className={styles["table__th"]}>Time Sent</div>
              <div className={styles["table__th"]}>HTTP Error Code</div>
              <div className={styles["table__th"]}>HTTP Error Message</div>
              <div className={styles["table__th"]}>Endpoint</div>
              <div className={styles["table__btn"]}></div>
            </div>
          </div>{" "}
          <div className={styles["table__body"]}>
            {
              currentTableData.length === 0 && (
                <div className={styles["table__item"]}>
                  <div className={styles["table__row"]}>
                    <div className={styles["table__td"]}>No Error Logs Found</div>
                  </div>
                </div>
              )
            }
            {currentTableData.map((item, index) => {
              return (
                <TableItem
                  isActive={isActive}
                  setIsActive={setIsActive}
                  itemData={item}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        <div className={styles["table__pagination"]}>
          <p className="sm">
            Showing {currentPage} - {Math.ceil(activeList.length / PageSize)} of{" "}
            {activeList.length} results
          </p>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
const TableItem = ({ itemData, isActive, setIsActive }) => {

  const formatObjectToBeutifiedString = (obj) => {
    return JSON.stringify(obj, null, 2);
  };

  return (
    <div className={styles["table__item"]}>
      <div
        className={styles["table__row"]}
        onClick={() => {
          if (isActive !== itemData.id) {
            setIsActive(itemData.id);
          } else {
            setIsActive(null);
          }
        }}
      >
        <div className={styles["table__td"]}>{itemData.timestamp}</div>
        <div className={styles["table__td"]}>{itemData.errorCode}</div>
        <div className={styles["table__td"]}>{itemData.errorMessage}</div>
        <div className={styles["table__td"]}> {itemData.endpoint}</div>
        <div
          className={classNames(styles["table__btn"], {
            [styles.active]: isActive === itemData.id,
          })}
        >
          {chevronBottom}
        </div>
      </div>
      <AnimatePresence>
        {isActive === itemData.id && (
          <motion.div
            className={styles["table__blocks"]}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            <h5>Log Details</h5>
            <div className={styles["table__blocks-row"]}>
              {" "}
              <div className={styles["table__block"]}>
                <div className={styles["table__block-head"]}>
                  <p className="sm">Request</p>
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(formatObjectToBeutifiedString(itemData.request));
                    }}
                  >
                    {copyIcon}
                    Copy code
                  </button>
                </div>
                <div className={styles["table__block-code"]}>
                  <CodeBlock text={formatObjectToBeutifiedString(itemData.request)} codeBlock />
                </div>
              </div>
              <div className={styles["table__block"]}>
                <div className={styles["table__block-head"]}>
                  <p className="sm">Response</p>
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(formatObjectToBeutifiedString(itemData.response));
                    }}
                  >
                    {copyIcon}
                    Copy code
                  </button>
                </div>
                <div className={styles["table__block-code"]}>
                  <CodeBlock text={formatObjectToBeutifiedString(itemData.response)} codeBlock />
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
