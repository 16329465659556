import Input from "components/input/input";
import React, { useEffect, useState } from "react";
import styles from "./create-api.module.scss";
import Button from "components/primary-button/button";
import { noteIcon } from "base/SVG";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../../../config';

export default function CreateApi({ setModal, setCreatedApiKey, apiList, setApiList }) {

  const [name, setName] = useState("");

  const createApiKey = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.post(SERVER_URL + '/api/endpoint/api-keys', {name}, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setCreatedApiKey(response.data.api_key);
          setApiList([...apiList, response.data.api_key]);
          setModal("create-success");
        })
        .catch((error) => {
          toast.error("Error creating API key: " + error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  return (
    <div className={styles["create"]}>
      <h4>Create API Key</h4>
      <Input placeholder={"Enter API key name"} onChange={(e) => setName(e.target.value)} label="API Key name" />
      <p className={styles["note"]}>
        {noteIcon}
        Generating this API will give access to all existing and future
        endpoints for the API key.
      </p>
      <div className={styles["create__btns"]}>
        <Button onClick={() => setModal(null)} type={"gray"} text={"Cancel"} />
        <Button
          onClick={() => createApiKey()}
          type={"primary"}
          text={"Generate"}
        />
      </div>
    </div>
  );
}
