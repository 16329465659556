import React, { useState, useEffect } from "react";
import styles from "./modals.module.scss";
import { lockIcon } from "base/SVG";
import Button from "components/primary-button/button";
import Input from "components/input/input";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../../config';

export default function ChangeFullname({ setModal, data, updateData }) {

  const [first_name, setFirst_name] = useState(data.first_name);
  const [last_name, setLast_name] = useState(data.last_name);
  const [organization, setOrganization] = useState(data.organization);

  const saveChanges = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));

    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.put(SERVER_URL + '/api/auth/user', {
        first_name: first_name,
        last_name: last_name,
        organization: organization
      }, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          updateData("first_name", first_name);
          updateData("last_name", last_name);
          updateData("organization", organization);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });

      setModal(null);
    }
  }

  return (
    <div className={styles["content"]}>
      <h4>Full Name</h4>
      <Input
        label="First Name"
        placeholder={"Enter your first name"}
        value={first_name}
        onChange={(e) => setFirst_name(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <Input
        label="Last Name"
        icon={lockIcon}
        placeholder={"Enter your last name"}
        value={last_name}
        onChange={(e) => setLast_name(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <Input
        label="Organization"
        placeholder={"Enter your organization"}
        value={organization}
        onChange={(e) => setOrganization(e.target.value)}
      />
      <div className={styles["space"]}></div>
      <div className={styles["content__btns"]}>
        <Button type="gray" text="Cancel" onClick={() => setModal(null)} />
        <Button
          type="primary"
          text="Save Changes"
          onClick={() => saveChanges()}
        />
      </div>
    </div>
  );
}
