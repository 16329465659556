import React from "react";
import styles from "./input-email.module.scss";
export default function InputEmail({
  label,
  icon,
  placeholder,
  onChange,
  value,
}) {
  return (
    <div className={styles["input__outer"]}>
      <label htmlFor="">{label}</label>
      <div className={styles["input"]}>
        <input
          type="email"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        <span>{icon}</span>
      </div>
    </div>
  );
}
