import Button from "components/primary-button/button";
import React from "react";
import styles from "./price.module.scss";
import { tickIcon } from "base/SVG";
const priceList = [
  {
    id: "1",
    type: "Trial",
    price: "$0",
    list: [
      {
        id: "1",
        text: "1 TX per 10 sec",
      },
      {
        id: "2",
        text: "Dedicated SWQoS",
      },
      {
        id: "3",
        text: "Anti-MEV",
      },
      {
        id: "4",
        text: "0.001 SOL Tip",
      }
    ],
  },
  {
    id: "2",
    type: "Entry",
    price: "$399",
    list: [
      {
        id: "1",
        text: "5 TPS",
      },
      {
        id: "2",
        text: "Dedicated SWQoS",
      },
      {
        id: "3",
        text: "Anti-MEV",
      },
      {
        id: "4",
        text: "0.001 SOL Tip",
      },
    ],
  },
  {
    id: "3",
    type: "Intermediate",
    price: "$999",
    list: [
      {
        id: "1",
        text: "20 TPS",
      },
      {
        id: "2",
        text: "Dedicated SWQoS",
      },
      {
        id: "3",
        text: "Anti-MEV",
      },
      {
        id: "4",
        text: "0.001 SOL Tip",
      },
    ],
  },
  {
    id: "4",
    type: "Advanced",
    price: "$1,999",
    list: [
      {
        id: "1",
        text: "50 TPS",
      },
      {
        id: "2",
        text: "Dedicated SWQoS",
      },
      {
        id: "3",
        text: "Anti-MEV",
      },
      {
        id: "4",
        text: "0.001 SOL Tip",
      },
    ],
  },
];
export default function Price() {
  return (
    <div className={styles["price"]}>
      <div className="auto__container">
        <div className={styles["price__head"]}>
          <h2>Plan Comparison</h2>
          <p>
            Explore our flexible options - try risk-free and upgrade or
            downgrade anytime. Your perfect plan awaits!
          </p>
        </div>
        <div className={styles["price__inner"]}>
          {priceList.map((item, index) => {
            return <PriceItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const PriceItem = (props) => {
  return (
    <div className={styles["priceItem"]}>
      <h6>{props.type}</h6>
      <div className={styles["priceItem__price"]}>
        <h3>{props.price}</h3>
        {props.price !== "Custom" && <p>/month</p>}
      </div>
      <div className={styles["priceItem__btn"]}>
        {props.price === "Custom" ? (
          <Button text="Contact Sales" type={"primary"} onClick={() => window.location.href = "https://t.me/nextblocksol"} />
        ) : (
          <Button text="Accelerate" type={"primary"} onClick={() => window.location.href = "/auth"} />
        )}
      </div>

      <ul>
        {props.list.map((item, index) => {
          return (
            <li {...index} key={index}>
              {tickIcon}
              {item.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
