import React from "react";
import styles from "./footer.module.scss";
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import { discordIcon, telegramIcon, twitterIcon } from "base/SVG";
export default function Footer() {
  return (
    <div className={styles["footer"]}>
      <div className="auto__container">
        <div className={styles["footer__inner"]}>
          <div className={styles["footer__inner-logo"]}>
            <img src={logo} alt="" />
          </div>
          <div className={styles["footer__inner-row"]}>
            <div className={styles["footer__inner-links"]}>
              <div className={styles["footer__inner-links-col"]}>
                <h5>Company</h5>
                <Link to="">Terms of Service</Link>
                <Link to="">Privacy Policy</Link>
              </div>{" "}
              <div className={styles["footer__inner-links-col"]}>
                <h5>Company</h5>
                <Link to="https://docs.nextblock.io/" target="_blank">Documentation</Link>
              </div>
            </div>
            <div className={styles["footer__inner-socials"]}>
              <h5>Social</h5>
              <div className={styles["footer__inner-socials-links"]}>
                <a href="https://t.me/nextblocksol" target="_blank">{telegramIcon}</a>
                <a href="https://discord.gg/nextblock" target="_blank">{discordIcon}</a>
                <a href="https://x.com/nextblock_sol" target="_blank">{twitterIcon}</a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["divider"]}></div>
        <div className={styles["footer__copy"]}>
          <p>© 2024 NextBlock. All rights reserved.</p>
          <Link to="">Terms of Service</Link>
        </div>
      </div>
    </div>
  );
}
