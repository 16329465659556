import React, { useEffect } from "react";
import logo from "assets/images/logo.png";
import styles from "./sidebar.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  analyticsIcon,
  apiIcon,
  billingIcon,
  dashboardIcon,
  settingsIcon,
  supportIcon,
  walletIcon,
  logoutIcon,
  noteIcon
} from "base/SVG";
import classNames from "classnames";
import Account from "components/account/account";

export default function Sidebar({ menu, setMenu, setPayment, user, logout }) {
  const location = useLocation();
  const navigate = useNavigate();

  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  const [showAccount, setShowAccount] = React.useState(false);

  // Check if screensize smaller then 1180px
  const handleResize = () => {
    if (window.innerWidth < 1180) {
      setShowAccount(true);
    } else {
      setShowAccount(false);
    }
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={classNames(styles["sidebar"], {
        [styles.active]: menu,
      })}
      onClick={close}
    >
      <div className={styles["sidebar__inner"]}>
        <div className={styles["sidebar__head"]}>
          <Link to={"/app/dashboard"} className={styles["sidebar__head-logo"]}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={styles["sidebar__body"]}>
          {
            showAccount && (
              <Account
                btnInner={
                  <>
                    <div className={styles["avatar"]}>
                      <div className={styles["avatar__title"]}>{user.first_name[0]}</div>
                    </div>
                  </>
                }
              >
                <button type="button" onClick={() => navigate("/app/settings")}>
                  {settingsIcon} <span>Settings</span>
                </button>
                <button type="button" onClick={() => logout()}>
                  {logoutIcon} <span>Logout</span>
                </button>
              </Account>
              
            )
          }
          <div className={styles["sidebar__mob"]}>
            <div
              className={styles["balance"]}
              onClick={() => {
                setPayment(true);
                window.scrollTo(0, 0);
                setMenu(false);
                document.body.classList.remove("active");
              }}
            >
              <div className={styles["balance__icon"]}>{walletIcon}</div>
              <div className={styles["balance__title"]}>
                <span>{user.balance} $</span>
              </div>
            </div>
          </div>
          <NavLink to="/app/dashboard" className={styles["sidebar__body-link"]}>
            {dashboardIcon} <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/app/usage-analytics"
            className={styles["sidebar__body-link"]}
          >
            {analyticsIcon} <span>Usage Analytics</span>
          </NavLink>
          <NavLink
            to="/app/endpoints-api"
            className={styles["sidebar__body-link"]}
          >
            {apiIcon} <span>Endpoint & API Key</span>
          </NavLink>
          <NavLink to="/app/billing" className={styles["sidebar__body-link"]}>
            {billingIcon} <span>Billing</span>
          </NavLink>
          <NavLink to="/app/support" className={styles["sidebar__body-link"]}>
            {noteIcon} <span>Documentation</span>
          </NavLink>
          <NavLink to="https://discord.gg/nextblock" className={styles["sidebar__body-link"]} target="_blank">
            {supportIcon} <span>Support</span>
          </NavLink>
          <NavLink to="/app/settings" className={styles["sidebar__body-link"]}>
            {settingsIcon} <span>Settings</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
