import React from "react";
import styles from "../add-funds.module.scss";
import Button from "components/primary-button/button";
import Counter from "components/counter/counter";
import classNames from "classnames";
import { motion } from "framer-motion";
import { loaderIcon } from "base/SVG";
import MoonLoader from "react-spinners/MoonLoader";


export default function Step3({ setModal, setStep, loaded, pay_amount, pay_currency, network, paymentLink="" }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, y: 20 }}
      className={styles["block"]}
    >
      <div className={styles["block__head"]}>
        <h4>Awaiting Confirmation</h4>
        <p className="sm">
          Your deposit is pending confirmation on the blockchain. This process
          may take several minutes.
        </p>
      </div>
      <div className={styles["block__qr"]}>
        <div className={styles["block__qr-price"]}>
          <h5>{pay_amount}</h5>
          <p>{pay_currency.toUpperCase().replace("USDCSOL", "USDC")}</p>
          <p>
            <small>{network.toUpperCase()}</small>
          </p>
        </div>
        <MoonLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "0vh auto",
            borderColor: "red",
          }}
          size={50}
        />
        {/*<Counter setStep={setStep} />*/}
      </div>
      <div className={styles["block__tool"]}>
        <p className="sm">
          You may close this window and check back in your Billing page later.
        </p>
      </div>
      <div className={classNames(styles["block__btns"], styles["center"])}>
        <Button
          type={"primary"}
          text={"Proceed to payment"}
          onClick={() => window.open(paymentLink, "_blank")}
        />
        <br />
        <Button
          type={"gray"}
          text={"Close"}
          onClick={() => {
            setModal(false);
          }}
        />
      </div>
    </motion.div>
  );
}
