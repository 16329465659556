import { mailModalIcon } from "base/SVG";
import React from "react";
import styles from "../auth.module.scss";
import Button from "components/primary-button/button";
import { useNavigate } from "react-router-dom";
export default function Mail() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles["auth__inner-icon"]}>{mailModalIcon}</div>
      <div className={styles["auth__inner-mail"]}>
        <h3>You’ve got mail</h3>
        <p>
          We just sent a verification link to your email, proceed with the link
          to create a new password.
        </p>
      </div>
      <div className={styles["auth__inner-btn"]}>
        <Button
          onClick={() => navigate("/auth/sign-in")}
          type={"gray"}
          text={"Go back to Login"}
        />
      </div>
    </>
  );
}
