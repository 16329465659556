import React from "react";
import styles from "../manage.module.scss";
import { copyIcon } from "base/SVG";
import { CodeBlock } from "react-code-blocks";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CurlRequest({rpcURL}) {

  console.log("rpcURL", rpcURL);

  return (
    <div className={styles["block"]}>
      <div className={styles["manage__title"]}>
        <h4>CURL request</h4>
      </div>
      <div className={styles["block__inner"]}>
        <div className={styles["block__inner-head"]}>
          <h5>Get Slot</h5>
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(
`curl -X POST \
  '` + rpcURL + `/api/v2/submit' \
  -H "Authorization: $AUTH_HEADER" \
  -H "Content-Type: application/json" \
  -d '{
    "transaction": {
      "content": "FtQ+KrJeNuiismV1Ke...DBABRQ=="
    },
    "frontRunningProtection": true
  }'`
              );
              toast.success("Code copied to clipboard", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }}
          >
            {copyIcon}
            Copy code
          </button>
        </div>
        <div className={styles["block__inner-code"]}>
          <CodeBlock
            text={
`curl -X POST 
  '` + rpcURL + `/api/v2/submit'
  -H "Authorization: $AUTH_HEADER"
  -H "Content-Type: application/json"
  -d '{
    "transaction": {
      "content": "FtQ+KrJeNuiismV1Ke...DBABRQ=="
    },
    "frontRunningProtection": true
  }'`
}
            codeBlock
          />
        </div>
      </div>
    </div>
  );
}
