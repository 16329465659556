import React from "react";
import styles from "../add-funds.module.scss";
import Button from "components/primary-button/button";
import classNames from "classnames";
import { motion } from "framer-motion";
import { cancelCircle } from "base/SVG";
import { useNavigate } from "react-router-dom";
export default function Step4({ setModal, amount }) {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, y: 20 }}
      className={styles["block"]}
    >
      <div className={styles["block__icon"]}>{cancelCircle}</div>
      <div className={styles["block__head"]}>
        <h4>Deposit Error</h4>
        <p className="sm">
          Your deposit of <strong>{amount} USD</strong> was not successful. If you believe this is an error, please contact support.
        </p>
      </div>

      <div className={classNames(styles["block__btns"], styles["center"])}>
        <Button
          type={"gray"}
          text={"Close"}
          onClick={() => {
            setModal(false);
            //navigate("./app/billing");
          }}
        />
      </div>
    </motion.div>
  );
}
