import React from "react";
import shape from "assets/images/shapes/shape3.png";
import styles from "./intro.module.scss";
import Button from "components/primary-button/button";
import { arrowRight } from "base/SVG";
export default function Intro() {
  return (
    <div className={styles["intro"]}>
      <div className={styles["intro__shape"]}>
        <img src={shape} alt="shape" />
      </div>
      <div className="auto__container">
        <div className={styles["intro__inner"]}>
          <div className={styles["intro__inner-tag"]}>
            <Button
              icon={arrowRight}
              text={"Nextblock is live"}
              type={"gray"}
              onClick={() => window.location.href = "/auth"}
            />
          </div>
          <h1>
            Lightning Fast <br />
            <span>Solana Transactions</span>
          </h1>
          <h4>
            Send transactions at unprecedented speeds, even during network
            congestion.
          </h4>
          <div className={styles["intro__inner-btn"]}>
            <Button icon={arrowRight} text={"Get Started"} type={"primary"} onClick={() => window.location.href = "/auth"} />
          </div>
        </div>
        <div className={styles["intro__inner"]}></div>
      </div>
    </div>
  );
}
