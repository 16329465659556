import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import Info from "./components/info/info";
import QuickAccess from "./components/quick-access/quick-access";
import SubPlan from "./components/sub-plan/sub-plan";

// third party
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// project import
import { SERVER_URL } from '../../../config';


export default function Dashboard() {
  const [loaded, setLoaded] = useState(false);

  const [usageData, setUsageData] = useState(undefined);
  const [billingDetails, setBillingDetails] = useState(undefined);

  const getUsageData = (startDate, endDate, callback = undefined) => {
    setLoaded(false);

    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/analytics/usage?startDate=' + startDate + '&endDate=' + endDate, {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setUsageData(response.data);
          
          if (callback) {
            callback();
          } else {
            setLoaded(true);
          }
          
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  const getBillingDetails = () => {
    // Check if user is logged in
    const token = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (!token) {
      window.location.href = '../../auth/sign-in';
    } else {
      // User is logged in
      axios.get(SERVER_URL + '/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token.split('=')[1]}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setBillingDetails(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  useEffect(() => {

    if (usageData == undefined) {
      let startDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
      let endDate = new Date().toISOString().split('T')[0];

      getUsageData(startDate, endDate, () => {
        if (billingDetails == undefined) {
          getBillingDetails();
        }
      });
    }

  }, []);
  
  if (!loaded) {
    return (
        <BarLoader
          color={"#26cead"}
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
    );
  }

  return (
    <div className={styles["dashboard"]}>
      <ToastContainer />
      <div className={styles["dashboard__head"]}>
        <div className={styles["dashboard__head-inner"]}>
          <h3>Welcome {billingDetails.first_name}</h3>
          <h5>Dashboard Overview</h5>
        </div>
      </div>
      <div className={styles["dashboard__body"]}>
        <div className={styles["dashboard__body-inner"]}>
          <Info usageData={usageData} billingDetails={billingDetails} />
          <QuickAccess />
          <SubPlan billingDetails={billingDetails} />
        </div>
      </div>
    </div>
  );
}
