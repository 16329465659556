import React, { useEffect, useState } from "react";
import logo from "assets/images/logo.png";
import Button from "components/primary-button/button";
import styles from "./header.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  return (
    <div className={styles["header"]}>
      <div className={"auto__container"}>
        <div className={styles["header__inner"]}>
          <Link to="" className={styles["header__inner-logo"]}>
            <img src={logo} alt="logo" />
          </Link>
          <div
            onClick={close}
            className={classNames(styles["nav"], {
              [styles.active]: menu,
            })}
          >
            <div className={styles["nav__inner"]}>
              <div className={styles["nav__inner-links"]}>
                <a href="#feature">Features</a>
                <a href="#pricing">Pricing</a>
                <a href="#contact">Contact</a>
                <a href="https://docs.nextblock.io/" target="_blank">Documentation</a>
              </div>
              <div className={styles["nav__inner-btn"]}>
                <Button
                  onClick={() => navigate("../../auth/sign-in")}
                  text="Sign In"
                  type={"primary"}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(styles["burger"], {
              [styles.active]: menu,
            })}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
