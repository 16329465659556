import React, { useState } from "react";
import styles from "./summary.module.scss";
import SelectSecondary from "components/select-secondary/select-secondary";
import Chart from "./chart";
import { counsDollarIcon, dollarIcon } from "base/SVG";

export default function Summary({ usageData, getUsageData, periodList, selectedPeriodList, setSelectedPeriodList }) {

  const updatePeriod = (item) => {
    setSelectedPeriodList(item);
    
    let startDate = "";
    let endDate = "";

    switch (item.id) {
      case "1":
        startDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        endDate = new Date().toISOString().split('T')[0];
        break;
      case "2":
        startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];
        endDate = new Date().toISOString().split('T')[0];
        break;
      case "3":
        startDate = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        endDate = new Date().toISOString().split('T')[0];
        
        // Minus 1 day so it does not exceed 90 days
        endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 2)).toISOString().split('T')[0];

        break;
    }

    getUsageData(startDate, endDate);

  }

  const [activeChart, setActiveChart] = useState("transactions"); // transactions or landingTime

  return (
    <div className={styles["summary"]}>
      <div className={styles["summary__head"]}>
        <h4>Usage Summary</h4>
        <p className="sm">
          Get a quick overview of your transaction volume and fees over the
          selected period.
        </p>
      </div>
      <div className={styles["summary__inner"]}>
        <div className={styles["overview"]}>
          <div className={styles["overview__head"]}>
            <h5>Usage Overview</h5>
            <div className={styles["overview__select"]}>
              <SelectSecondary list={periodList} selected={selectedPeriodList} onChange={updatePeriod} />
            </div>
          </div>
          <div className={styles["overview__body"]}>
            <div className={styles["overviewItem"]} onClick={() => setActiveChart("transactions")}>
              <div
                style={{ background: "#FF696F" }}
                className={styles["overviewItem__icon"]}
              >
                {dollarIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Total Transactions Sent</p>
                <h4>{usageData.totalTransactions}</h4>
              </div>
            </div>{" "}
            <div className={styles["overviewItem"]} onClick={() => setActiveChart("landingTime")}>
              <div
                className={styles["overviewItem__icon"]}
                style={{ background: "#61E5FC" }}
              >
                {counsDollarIcon}
              </div>
              <div className={styles["overviewItem__text"]}>
                <p className="sm">Median Landing Time</p>
                <h4>{usageData.medianLandingTime} slots</h4>
              </div>
            </div>
            <p>Click above to switch between charts</p>
          </div>
        </div>
        <Chart timeSeriesMetrics={usageData.timeSeriesMetrics} grouping={usageData.grouping} activeChart={activeChart} />
      </div>
    </div>
  );
}
