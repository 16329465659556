import React from "react";
import styles from "./input-name.module.scss";

export default function InputName({
  firstNameLabel,
  lastNameLabel,
  firstNamePlaceholder,
  lastNamePlaceholder,
  onFirstNameChange,
  onLastNameChange,
  firstNameValue,
  lastNameValue,
}) {
  return (
    <div className={styles["input__outer"]}>
      <div className={styles["input__half"]}>
        <label htmlFor="">{firstNameLabel}</label>
        <div className={styles["input"]}>
          <input
            type="text"
            placeholder={firstNamePlaceholder}
            onChange={onFirstNameChange}
            value={firstNameValue}
          />
        </div>
      </div>
      <div className={styles["input__half"]}>
        <label htmlFor="">{lastNameLabel}</label>
        <div className={styles["input"]}>
          <input
            type="text"
            placeholder={lastNamePlaceholder}
            onChange={onLastNameChange}
            value={lastNameValue}
          />
        </div>
      </div>
    </div>
  );
}
